export const base_url = "https://api.charznet.in";
export const image_base_url =
  "https://api.charznet.in/uploads/users/";
export const users_image_base_url =
  "https://api.charznet.in/uploads/users/";
export const station_image_base_url =
  "https://api.charznet.in/uploads/station_images/";
export const ports_image_base_url =
  "https://api.charznet.in/uploads/port_images/";
export const country_image_base_url =
  "https://api.charznet.in/uploads/country_images/";
export const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTg4MTAyMjFkYWU3N2Nk";
